import Base from './base'

export default class Reservation extends Base {
  init () {
    this.id = null
    this.booking_number = null
    this.confirm_number = null
    this.last = null
    this.first = null
    this.accompany = null
    this.contact_number = null
    this.email = null
    this.id_type = 'ID'
    this.id_number = null
    this.gender = 'Male'
    this.invitation_code = null
    this.language = null
    this.arrival = null
    this.arrival_time = '14-18'
    this.checked_in = null
    this.departure = null
    this.departure_time = 'Before12'
    this.checked_out = null
    this.checked_out_date = null
    this.nights = 0
    this.stay_nights = 0
    this.rooms = 1
    this.room_number = null
    this.rate_amount = 0
    this.total_amount = 0
    this.status = null
    this.payment_method = null
    this.payment_status = 'Unpaid'
    this.last_pay_time = null
    this.remark = null
    this.reference = null
    this.conference_id = null
    this.hotel_id = null
    this.room_type_id = null

    return this
  }
}
