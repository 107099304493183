export default class Base {
  init () {

  }

  constructor (options) {
    this.init()
    if (options) {
      for (const key of Object.keys(options)) {
        this[key] = options[key]
      }
    }
  }
}
