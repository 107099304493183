import Base from './base'

export default class RoomType extends Base {
  init () {
    this.id = null
    this.name = null
    this.bed = null
    this.breakfast = null
    this.rate_amount = 0
    this.images = []

    return this
  }
}
