export default {
  data () {
    return {
      day: '-',
      hour: '-',
      minute: '-',
      second: '-'
    }
  }
}
