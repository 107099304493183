import Base from './base'

export default class Hotel extends Base {
  init () {
    this.id = null
    this.name = null
    this.description = null
    this.address = null
    this.star = 5
    this.currency_code = null
    this.rate_amount = 0
    this.images = []
    this.facilities = ''

    return this
  }
}
